<template>
  <header class="header">
    <nav class="d-flex align-items-center container">
      <a class="arrow-back" @click="$router.back()">
        <i class="ol-fa fas fa-arrow-left"></i>
        <i class="ol-fa fas fa-chevron-left"></i>
      </a>
      <!-- LOGO -->
      <div class="logo">
        <a href="/" style="margin-right: 0px">
          <img class="logo-header" src="https://cdn.morefurniture.id/MORE/asset/morelogo_nd.png" />
        </a>
      </div>
      <h2>Checkout</h2>

      <a href="/cart" style="width: 20px; display: none" class="search-addtocart-header">
        <i class="fa fa-shopping-cart" style="width: 100%; font-size: 20px"></i>
      </a>
      <input type="checkbox" id="menu" name="menu" class="m-menu__checkbox" />
    </nav>
  </header>
</template>

<script>
import { debounce } from "lodash";
import axios from "axios";
import http from "@/lib/axios";
import globalUrl from "@/util/globallUrl";
const PRODUCT = globalUrl.WS_PRODUCT;
import { mapGetters, mapActions } from "vuex";
import CategoriesHeader from "@/components/product/CategoriesHeader";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { onBackgroundMessage } from "firebase/messaging/sw";
import NotificationItemTop from "@/components/notification/NotificationItemTop";
export default {
  name: "Header",
  components: {
    CategoriesHeader,
    NotificationItemTop,
  },
  data() {
    return {
      isDev: true,
      search: "",
      loading: true,
      fbt: null,
      queue: null,
      results: [],
    };
  },
  computed: {
    ...mapGetters("auth", ["authenticated", "user", "fbToken", "accessToken"]),
    ...mapGetters("product/list", [
      "categoriesJson",
      "filtered",
      "categoriesGroupJson",
    ]),
    ...mapGetters("transaction/cart", ["variants"]),
    ...mapGetters("notification", ["unread", "data", "laravelPagenation"]),
    ...mapGetters("chats", ["inbox"]),
    filteredList() {
      return this.fruits.filter((item) => {
        return (
          this.search &&
          item
            .toLowerCase()
            .replaceAll(" ", "-")
            .includes(this.search.toLowerCase().replaceAll(" ", "-"))
        );
      });
    },
  },
  methods: {
    ...mapActions("auth", ["logout", "SaveUserToken"]),
    ...mapActions("product/list", [
      "getCategoriesJson",
      "getCategoriesGroupJson",
    ]),
    ...mapActions("notification", ["fetchData"]),
    ...mapActions("chats", ["getInbox"]),
    ...mapActions("transaction/cart", ["save", "get"]),
    searchProduct: function () {
      $(".ul-search").show();
      http.get(`uni/product/search?q=${this.search}`).then((response) => {
        this.results = response.data.data.results;
      });
    },
    searchKeyword(keyword) {
      this.search = keyword;
      this.searching();
    },
    hideSearch() {
      $(".ul-search").hide();
    },
    setMenuMobile() {
      let routeName = this.$route.name;
      switch (routeName) {
        case "ProductDetail":
          // console.log($(window).width());
          if ($(window).width() < 768) {
            $(".search-addtocart-header").css("display", "block");
          }

          break;
        default:
          break;
      }
    },
    setAppEnv(data) {
      this.isDev = data === "local" ? true : false;
    },
    async initFbToken() {
      const firebaseApp = initializeApp({
        apiKey: "AIzaSyD9FfhX-T95OS89WB8uJUSWPztrMwcl7Pw",
        authDomain: "more-306503.firebaseapp.com",
        projectId: "more-306503",
        storageBucket: "more-306503.appspot.com",
        messagingSenderId: "24556042365",
        appId: "1:24556042365:web:887c3b13ec35009d529914",
        measurementId: "G-MRZ9DMPX3W",
      });
      const messaging = getMessaging(firebaseApp);

      Notification.requestPermission().then((data) => {
        getToken(messaging, { vapidKey: process.env.VUE_APP_VAPIDKEY })
          .then((currentToken) => {
            if (currentToken) {
              this.fbt = currentToken;
              this.setFbToken();
            } else {
              Notification.requestPermission();
            }
          })
          .catch((err) => {
            // console.error(err);
          });
      });

      onMessage(messaging, (payload) => {
        var notif = payload.notification;
        var data = payload.data;
        var notification = new Notification(notif.title, {
          body: notif.body,
          icon: data.image,
        });
        notification.close();
        this.getData();
        this.getInbox();
      });
    },
    setFbToken() {
      if (this.authenticated) {
        var formdata = new FormData();
        formdata.append("device_id", 1);
        formdata.append("device_name", "web");
        formdata.append("device_os", "web");
        formdata.append("access_token", this.accessToken);
        formdata.append("firebase_token", this.fbt);
        formdata.append("app_version", "more v2");
        if (_.isEmpty(localStorage.getItem("fbtoken"))) {
          // console.log("null fbt");
          this.SaveUserToken(formdata);
        } else if (
          !_.isEmpty(localStorage.getItem("fbtoken")) &&
          localStorage.getItem("fbtoken") != this.fbt
        ) {
          this.SaveUserToken(formdata);
        }
      }
    },
    filtering(subCategory) {
      $(".header-menu-ruangan").trigger("click");
      this.$store.commit("product/list/resetFilter", true);
      this.$store.commit("product/list/setFiltered", {
        categories: [subCategory.id],
      });
      if (this.$route.name != "Products") this.$router.push("/products");
      window.scrollTo(0, 0);
    },
    toInbox() {
      if (this.authenticated) {
        this.$router.replace("/profile/chats");
      } else {
        this.$router.replace("/login");
      }
    },
    async getData(page = 1) {
      let data = {
        page: 1,
        per_page: 4,
      };
      await this.fetchData(data);
      // console.log(this.data.lenght);
    },
    searching() {
      fbq("track", "Search", {
        content_category: "Search",
        currency: "IDR",
        search_string: this.search,
        value: this.search,
      });

      this.$analytics.fbq.event("Search", {
        keyword: this.search,
      });
      //this.$gtag.event('Search', { keyword: this.search });
      window.location.href = "/products/q=" + this.search;
      // if (this.$route.name != "Products") {
      //     this.$store.commit("product/list/setCategoryGroup", null);
      //     this.$store.commit("product/list/resetFilter");
      //     this.$store.commit("product/list/setFiltered", { q: this.search });
      //     this.$router.push('/products')
      // }else{
      //     this.$store.commit("product/list/resetFilter");
      //     this.$store.commit("product/list/setFiltered", { q: this.search });
      // }
      // window.scrollTo(0, 0);
      // console.log(this.search);
    },
    searchMobile() {
      var val = $("#input-search-mobile").val();
      // alert(val);
      fbq("track", "Search", {
        content_category: "Search",
        currency: "IDR",
        search_string: val,
        value: val,
      });
      if (this.$route.name != "Products") {
        this.$store.commit("product/list/setCategoryGroup", null);
        this.$store.commit("product/list/resetFilter");
        this.$store.commit("product/list/setFiltered", { q: val });
        this.$router.push("/products");
      } else {
        this.$store.commit("product/list/resetFilter");
        this.$store.commit("product/list/setFiltered", { q: val });
      }

      this.$analytics.fbq.event("Search", {
        keyword: val,
      });
      //this.$gtag.event('Search', { keyword: val });
      window.scrollTo(0, 0);
    },
    filterGroupCategories(val) {
      this.$store.commit("product/list/resetFilter", true);
      this.$store.commit("product/list/setFiltered", {
        category_group: val,
      });
      if (this.$route.name != "Products") this.$router.push("/products");
      window.scrollTo(0, 0);
    },
    filterBrand(val) {
      this.$store.commit("product/list/resetFilter", true);
      this.$store.commit("product/list/setFiltered", {
        brands: [val],
      });
      if (this.$route.name !== "Products") this.$router.push("/products");
      window.scrollTo(0, 0);
    },
    allProduct(val) {
      this.$store.commit("product/list/resetFilter", true);
      this.$store.commit("product/list/setFiltered", {
        type: val,
      });
      if (this.$route.name != "Products") this.$router.push("/products");
      window.scrollTo(0, 0);
      // this.toogleMenu();
    },
    bundling() {
      this.$store.commit("product/list/resetFilter", true);
      this.$store.commit("product/list/setFiltered", {
        category_group: [123],
      });
      if (this.$route.name != "Products") this.$router.push("/products");
      // this.toogleMenu();
    },

    removeVariant(variant) {
      this.$store.commit("transaction/cart/remove", variant);
      this.synching();
    },
    toogleMenu() {
      if ($("#menu").is(":checked")) {
        $("#menu").trigger("click");
      }
    },
    async getCart() {
      await this.get();
      this.$store.commit("transaction/checkout/setItems", this.variants);
    },
    async synching(usingQueue = true) {
      if (this.authenticated) {
        if (this.queue != null) clearTimeout(this.queue);
        if (usingQueue) {
          this.queue = setTimeout(async () => {
            await this.save();
          }, 250);
        } else {
          await this.save();
        }
      }
    },
    jq() {
      let jq = {
        menuFixed: () => {
          1 < document.body.scrollTop || 1 < document.documentElement.scrollTop
            ? $("#body").addClass("floating")
            : $("#body").removeClass("floating");
        },
        scrolled: () => {
          $(window).on("scroll", function () {
            1 < $(window).scrollTop() &&
              ($(".ol-header-category__nav > li > a").removeClass("active"),
                $(".ol-header-category__nav-sub").hide(),
                $("#ol-header-fix").css("min-height", ""),
                $(".ol-header-category-content").removeClass("show"));
            jq.menuFixed();
          });
        },
        init: () => {
          jq.scrolled();
        },
      };
      return jq.init();
    },
  },
  async mounted() {
    await this.getCart();
    await this.getCategoriesJson();
    await this.getCategoriesGroupJson();
    this.search = this.filtered.q;
    this.setAppEnv(process.env.appEnv);
    this.jq();
    this.initFbToken();
    this.getData();
    this.getInbox();
    this.setMenuMobile();

    $(".m-menu__overlay").on("click", function (event) {
      const body = document.body;
      body.style.overflow = "visible";
    });

    $(".m-menu__toggle_close").on("click", function (event) {
      const body = document.body;
      body.style.overflow = "visible";
    });

    $(".m-menu__toggle").on("click", function (event) {
      const body = document.body;
      body.style.overflow = "hidden";
    });

    $(".toggle-parent").on("click", function (event) {
      var a = $(this).attr("for");
      var check = $("." + a).css("display");
      if (check == "block") {
        $("." + a).css("display", "none");
      } else {
        $(".ul-parent").css("display", "none");
        $("." + a).css("display", "block");
      }
    });

    $(".toggle-child").on("click", function (event) {
      var a = $(this).attr("for");
      var check = $("." + a).css("display");
      if (check == "block") {
        $("." + a).css("display", "none");
      } else {
        $(".ul-child").css("display", "none");
        $("." + a).css("display", "block");
      }
    });
  },
  watch: {
    authenticated: function (value) {
      if (!value) this.$router.replace("/");
    },
    "filtered.q": function (value) {
      this.search = value;
    },
    // search: debounce(function () {
    //     this.searchProduct();
    // }, 1000),
  },
};
</script>

<style>
.searchListMainDiv ul {
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 0.5rem;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 50px;
  background: white;
  width: 40%;
  z-index: 99 !important;
  border-radius: 4px;
  /* border: 4px solid #E8EAED; */
}

.searchListMainDiv ul::-webkit-scrollbar {
  width: 5px;
}

.searchListMainDiv ul::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ddd;
  border-radius: 10px;
}

.searchListMainDiv ul::-webkit-scrollbar-thumb {
  background: rgb(183, 183, 183);
  border-radius: 10px;
}

.searchListMainDiv ul::-webkit-scrollbar-thumb:hover {
  background: #a2a2a2;
}

.searchListMainDiv ul li {
  /* padding: 1.2rem 10px; */
  padding: 16px 8px;
  font-size: 15px;
  font-weight: 500;
  line-height: 0;
  /* border-bottom: 1px solid #ddd; */
  color: #333;
  cursor: pointer;
}

.searchListMainDiv ul li:last-child {
  border: none;
}
</style>

<style scoped>
.header {
  position: sticky;
}

.header>nav {
  gap: 10px;
  justify-content: start;
  padding-bottom: 10px;
  padding-top: 10px;
}

.header .arrow-back {
  font-size: 16px;
}

.header .arrow-back .fa-chevron-left {
  display: none;
}

.header h2 {
  font-size: 20px;
}

.header .logo {
  position: absolute;
  right: 20px;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .header>nav {
    gap: 16px;
    min-height: 82px;
  }

  .header .arrow-back .fa-arrow-left {
    display: none;
  }

  .header .arrow-back .fa-chevron-left {
    display: inline;
  }

  .header h2 {
    color: #141414;
    font-size: 28px;
    font-weight: bold;
    /* display: none; */
  }

  .header .logo {
    display: block;
    height: 32px;
    position: static;
    width: 34.64px;
  }

  .header .logo a {
    width: 100%;
  }

  .header .logo a .logo-header {
    height: 100%;
    width: 100%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (max-width: 992px) {}
</style>
